<template>
  <v-container fluid grid-list-lg style="overflow: scroll">


    <v-card class="rounded-xl" outlined>
      <v-card-title primary-title>
        <v-layout row wrap align-center class="mb-3">
          <v-flex shrink>
            <v-btn icon outlined @click.stop.prevent="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink class="font-weight-bold black--text">
            Transactions
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink class="text-right">
            <SearchBar v-model="search" />
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-card flat class="cardTitle" :color="$style.card.background">
          <v-card-text>
            <v-layout row wrap>
              <v-flex shrink>
                <DateTimePicker :range="true" v-model="dateTime" :initStartDate="getStart" :initEndDate="getEnd"
                  @submit="getList()" :defaultRange="defaultRange" />
              </v-flex>
              <!-- <v-flex class="ml-2" shrink> -->
              <!-- <v-select
                hide-details
                item-text="name"
                :items="items"
                return-object
                v-model="selected"
                solo
              ></v-select> -->
              <!-- </v-flex> -->
              <v-spacer></v-spacer>
              <v-flex shrink v-if="
  !$vuetify.breakpoint.xsOnly
">
                <DateSelectorView :dateTime="dateTime" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-data-table class="table-header" hide-default-footer :items-per-page="serials.length" :headers="headers"
          :items="serials" :loading="loading">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td>{{ item.createdAt | moment('MM/DD/YYYY LT') }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.phoneNumber }}</td>
              <td class="text-capitalize">{{ item.type }}</td>
              <!-- <td>{{ item.Carrier.name }}</td> -->
              <td>
                <v-chip class="text-capitalize" small label dark :color="color(item.status)">
                  {{ item.status }}
                </v-chip>
              </td>
            </tr>

          </template>
        </v-data-table>
      </v-card-text>

    </v-card>
  </v-container>
</template>
<script>
import historyService from '@/modules/History/service.js';
import DateTimePicker from '@/components/DateTimePicker';
import DateSelectorView from '@/components/DateSelectorView.vue';

export default {
  name: 'sim-history',
  props: ['hideHeader', 'hideCreate', 'defaultRange'],
  components: {
    DateTimePicker,
    DateSelectorView
  },
  data() {
    return {
      start: this.$moment().startOf('day'),
      end: this.$moment().endOf('day'),
      dateTime: {
        start: this.$moment().startOf('day').toISOString(),
        end: this.$moment().endOf('day').toISOString(),
      },
      activationDialog: false,
      selectedSerial: null,
      edit: false,
      status: 0,
      headers: [
        {
          text: 'Date',
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          sortable: true,
        },
        {
          text: "Phone Number",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
      ],
      search: null,
      serials: [],
      count: 0,
      loading: false,
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      ))

    };
  },
  computed: {
    getStart() {
      if (this.defaultRange) return null
      return this.start
    },
    getEnd() {
      if (this.defaultRange) return null
      return this.end
    },
  },
  created() {
    this.getList();

  },
  watch: {
    search() {
      if (this.search.length > 3 || this.search.length === 0) {
        this.getList();
      }
    },
    searchInDate() {
      this.getList();
    },
  },
  methods: {
    color(val) {
      let value = null
      switch (val) {
        case 'paid':
          value = 'green'
          break;
        case 'pending':
          value = 'yellow accent-4'
          break;
        case 'open':
          value = 'red'
          break;
        case 'closed':
          value = 'green'
          break;
        case 'rejected':
          value = 'red accent-4'
          break;
        // default:
        // value = 'grey'
        // 	break;
      }
      // console.log('value', value, val)
      return value
    },
    getList() {
      this.loading = true;
      console.log('this.search is :', this.search)
      let start = this.dateTime.start;
      let end = this.dateTime.end;
      return historyService
        .getOrders({
          start,
          end,
          limit: 500,
          search: this.search,
          orderBy: ['id', true],
          contactId: this.contact.id,
          cellsimPortalList: true
        })
        .then(response => {
          console.log('response is :', response)
          this.serials = response.data;
          this.count = response.count;
          this.loading = false;
          return response;
        })
        .catch(error => {
          console.log('error is :', error)
        })
    },
  },
  sockets: {
    INVOICECREATED(data) {
      console.log('data is :', data)
      console.log('INVOICECREATED data', data)
      if (data.ContactId && data.ContactId == this.AssignedContactId) {

      }
      this.getList()
      // this.getList()
    },
    INVOICEUPDATED(data) {
      console.log('INVOICEUPDATED data', data)
      if (data.ContactId && data.ContactId == this.AssignedContactId)
        this.getList()
    },
    INVOICEDELETED(data) {
      console.log('INVOICEDELETED data', data)
      if (data.ContactId && data.ContactId == this.AssignedContactId)
        this.getList()
    }

  },
};
</script>
